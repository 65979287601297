export class Modal {
  modalClass = 'modal';
  wrapperClass = 'wrapper';
  closeBtnClass = 'close-btn';
  animTime = 300;

  constructor() {
  }

  createElement(type, className) {
    const el = document.createElement(type);
    el.classList.add(className);

    return el;
  }

  createModalEl() {
    const modal = this.createElement('div', this.modalClass);
    modal.style.opacity = `0`;
    modal.style.transition = `all ${this.animTime}ms`;

    return modal;
  }

  createModal() {
    const modalEl = this.createModalEl();
    const wrapperEl = this.createElement('div', this.wrapperClass);
    const closeBtnEl = this.createElement('div', this.closeBtnClass);

    modalEl.appendChild(wrapperEl);
    modalEl.appendChild(closeBtnEl);

    document.body.insertAdjacentHTML('beforeend', modalEl.outerHTML);
  }

  open() {
    this.createModal();

    const modalEl = document.body.querySelector(`.${this.modalClass}`);
    setTimeout(() => modalEl.style.opacity = '1');

    document.body.style.overflow = 'hidden';

    document.addEventListener('keydown', this._escKeyListener);
    document.querySelector(`.${this.closeBtnClass}`)
      .addEventListener('click', () => this._close());
  }

  _escKeyListener = (event) => {
    if (event.keyCode === 27) { this._close() }
    document.removeEventListener('keydown', this._escKeyListener);
  }

  _close() {
    const modalEl = document.body.querySelector(`.${this.modalClass}`);

    modalEl.style.opacity = '0';
    document.body.style.overflow = 'auto';

    setTimeout(() => document.body.removeChild(modalEl), this.animTime);
  }
}

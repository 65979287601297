import {Modal} from "./Modal";

export class ModalVideo extends Modal {
  iframeContainerClass = 'iframeContainer';

  constructor(url, node = null) {
    super();

    this.videoUrl = url;
    this.additionalNode = node;
  }

  createIframeElem() {
    const iframeElem = document.createElement('iframe');
    iframeElem.setAttribute('allow', 'autoplay');
    iframeElem.setAttribute('src', this.videoUrl);

    return iframeElem;
  }

  createModal() {
    const modalVideoEl = this.createModalEl();
    const iframeEl = this.createIframeElem();
    const iframeContainerEl = this.createElement('div', this.iframeContainerClass);
    const wrapperEl = this.createElement('div', this.wrapperClass);
    const closeBtnEl = this.createElement('div', this.closeBtnClass);

    iframeContainerEl.appendChild(iframeEl);
    wrapperEl.appendChild(iframeContainerEl);
    modalVideoEl.appendChild(wrapperEl);
    modalVideoEl.appendChild(closeBtnEl);

    if (this.additionalNode) {
      wrapperEl.appendChild(this.additionalNode);
    }

    document.body.insertAdjacentHTML('beforeend', modalVideoEl.outerHTML);
  }
}

import { ModalVideo } from "./models/ModalVideo";

const $video = document.querySelector('#video');
const $shortVideo = document.querySelector('#short-video');
const $shortVideoTestimonial = document.querySelector('#short-video-testimonial');

const modalVideo = new ModalVideo('https://player.vimeo.com/video/712449888?autoplay=1');
const shortModalVideo = new ModalVideo('https://player.vimeo.com/video/407056014?autoplay=1');
const shortModalVideoTestimonial = new ModalVideo('https://player.vimeo.com/video/415209930?autoplay=1');

$video.addEventListener('click', () => modalVideo.open());
$shortVideo.addEventListener('click', () => shortModalVideo.open());
$shortVideoTestimonial.addEventListener('click', () => shortModalVideoTestimonial.open());
